import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d1eccb7 = () => interopDefault(import('../components/pages/image-viewer/ImageViewer.vue' /* webpackChunkName: "pages/image-viewer/_username/_parent/_id" */))
const _33a86351 = () => interopDefault(import('../pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _3dbcb3e6 = () => interopDefault(import('../pages/best-wedding-vendors-2024/index.vue' /* webpackChunkName: "pages/best-wedding-vendors-2024/index" */))
const _0012cdc4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _7361e410 = () => interopDefault(import('../pages/forms/index.vue' /* webpackChunkName: "pages/forms/index" */))
const _4d5be7a4 = () => interopDefault(import('../pages/guides/index.vue' /* webpackChunkName: "pages/guides/index" */))
const _a4497ff6 = () => interopDefault(import('../pages/i-do-crew.vue' /* webpackChunkName: "pages/i-do-crew" */))
const _185fd3ec = () => interopDefault(import('../pages/planning/index.vue' /* webpackChunkName: "pages/planning/index" */))
const _2e919b0d = () => interopDefault(import('../pages/podcast/index.vue' /* webpackChunkName: "pages/podcast/index" */))
const _ea1f76c6 = () => interopDefault(import('../pages/questionnaires/index.vue' /* webpackChunkName: "pages/questionnaires/index" */))
const _4b9519f7 = () => interopDefault(import('../pages/real-weddings/index.vue' /* webpackChunkName: "pages/real-weddings/index" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _34ed5763 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _211f388a = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _3a585daa = () => interopDefault(import('../pages/social-callback.vue' /* webpackChunkName: "pages/social-callback" */))
const _2f62261e = () => interopDefault(import('../pages/style-quiz/index.vue' /* webpackChunkName: "pages/style-quiz/index" */))
const _45d7b6b8 = () => interopDefault(import('../pages/tools/index.vue' /* webpackChunkName: "pages/tools/index" */))
const _15de1774 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _75a474e1 = () => interopDefault(import('../pages/dashboard/events/index.vue' /* webpackChunkName: "pages/dashboard/events/index" */))
const _c6bf27e4 = () => interopDefault(import('../pages/event-invitation/n______deactivated_loverly-list-event-2024.vue' /* webpackChunkName: "pages/event-invitation/n______deactivated_loverly-list-event-2024" */))
const _70e49701 = () => interopDefault(import('../pages/style-quiz/result.vue' /* webpackChunkName: "pages/style-quiz/result" */))
const _63fbccac = () => interopDefault(import('../pages/tools/cash-registry/index.vue' /* webpackChunkName: "pages/tools/cash-registry/index" */))
const _fd279758 = () => interopDefault(import('../pages/tools/guest-list/index.vue' /* webpackChunkName: "pages/tools/guest-list/index" */))
const _b392f496 = () => interopDefault(import('../pages/tools/offers.vue' /* webpackChunkName: "pages/tools/offers" */))
const _59c5b780 = () => interopDefault(import('../pages/tools/submit-wedding/index.vue' /* webpackChunkName: "pages/tools/submit-wedding/index" */))
const _64aeb06e = () => interopDefault(import('../pages/tools/vendor-manager/index.vue' /* webpackChunkName: "pages/tools/vendor-manager/index" */))
const _3b2656ce = () => interopDefault(import('../pages/tools/vendor-submit-wedding/index.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/index" */))
const _1ad1fcf9 = () => interopDefault(import('../pages/tools/videos/index.vue' /* webpackChunkName: "pages/tools/videos/index" */))
const _02e70302 = () => interopDefault(import('../pages/tools/wedding-checklist/index.vue' /* webpackChunkName: "pages/tools/wedding-checklist/index" */))
const _69c6d868 = () => interopDefault(import('../pages/vendor/checklist/index.vue' /* webpackChunkName: "pages/vendor/checklist/index" */))
const _868a498c = () => interopDefault(import('../pages/vendor/dashboard/index.vue' /* webpackChunkName: "pages/vendor/dashboard/index" */))
const _c14b4bcc = () => interopDefault(import('../pages/tools/cash-registry/setup/index.vue' /* webpackChunkName: "pages/tools/cash-registry/setup/index" */))
const _61acd2fd = () => interopDefault(import('../pages/tools/create-or-claim-vendor-profile/setup/index.vue' /* webpackChunkName: "pages/tools/create-or-claim-vendor-profile/setup/index" */))
const _0e6e787f = () => interopDefault(import('../pages/tools/guest-list/setup.vue' /* webpackChunkName: "pages/tools/guest-list/setup" */))
const _06a5833e = () => interopDefault(import('../pages/tools/submit-wedding/create.vue' /* webpackChunkName: "pages/tools/submit-wedding/create" */))
const _084a654a = () => interopDefault(import('../pages/tools/submit-wedding/email.vue' /* webpackChunkName: "pages/tools/submit-wedding/email" */))
const _6337d3ce = () => interopDefault(import('../pages/tools/submit-wedding/my-weddings.vue' /* webpackChunkName: "pages/tools/submit-wedding/my-weddings" */))
const _157bbfe7 = () => interopDefault(import('../pages/tools/vendor-manager/hired.vue' /* webpackChunkName: "pages/tools/vendor-manager/hired" */))
const _5aaaebf4 = () => interopDefault(import('../pages/tools/vendor-manager/setup.vue' /* webpackChunkName: "pages/tools/vendor-manager/setup" */))
const _da3bed76 = () => interopDefault(import('../pages/tools/vendor-submit-wedding/create.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/create" */))
const _de1cfb3a = () => interopDefault(import('../pages/tools/vendor-submit-wedding/email.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/email" */))
const _5c5e0bb2 = () => interopDefault(import('../pages/tools/vendor-submit-wedding/my-weddings.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/my-weddings" */))
const _d6d86544 = () => interopDefault(import('../pages/tools/vision-boards/settings.vue' /* webpackChunkName: "pages/tools/vision-boards/settings" */))
const _448baa55 = () => interopDefault(import('../pages/tools/vision-boards/setup/index.vue' /* webpackChunkName: "pages/tools/vision-boards/setup/index" */))
const _5fb044ba = () => interopDefault(import('../pages/tools/wedding-checklist/settings.vue' /* webpackChunkName: "pages/tools/wedding-checklist/settings" */))
const _e02d71a6 = () => interopDefault(import('../pages/tools/wedding-checklist/setup.vue' /* webpackChunkName: "pages/tools/wedding-checklist/setup" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _274d5554 = () => interopDefault(import('../pages/tools/create-or-claim-vendor-profile/setup/_step.vue' /* webpackChunkName: "pages/tools/create-or-claim-vendor-profile/setup/_step" */))
const _da6b5fae = () => interopDefault(import('../pages/tools/videos/categories/_taxonomy/index.vue' /* webpackChunkName: "pages/tools/videos/categories/_taxonomy/index" */))
const _618fa6a4 = () => interopDefault(import('../pages/tools/vision-boards/setup/_step.vue' /* webpackChunkName: "pages/tools/vision-boards/setup/_step" */))
const _5722dc5a = () => interopDefault(import('../pages/vendor/dashboard/posse/_user/events/_id.vue' /* webpackChunkName: "pages/vendor/dashboard/posse/_user/events/_id" */))
const _2cf39168 = () => interopDefault(import('../pages/dashboard/events/_id/index.vue' /* webpackChunkName: "pages/dashboard/events/_id/index" */))
const _3112498c = () => interopDefault(import('../pages/internal/paid-documents/_id.vue' /* webpackChunkName: "pages/internal/paid-documents/_id" */))
const _f35f599e = () => interopDefault(import('../pages/real-weddings/styles/_taxonomy/index.vue' /* webpackChunkName: "pages/real-weddings/styles/_taxonomy/index" */))
const _54e24bc5 = () => interopDefault(import('../pages/tools/courses/_slug.vue' /* webpackChunkName: "pages/tools/courses/_slug" */))
const _1d633bbc = () => interopDefault(import('../pages/tools/guest-list/_id.vue' /* webpackChunkName: "pages/tools/guest-list/_id" */))
const _68ea6e68 = () => interopDefault(import('../pages/vendor/management/_step.vue' /* webpackChunkName: "pages/vendor/management/_step" */))
const _cca1e960 = () => interopDefault(import('../pages/vendors/services/_taxonomy/index.vue' /* webpackChunkName: "pages/vendors/services/_taxonomy/index" */))
const _73673a84 = () => interopDefault(import('../pages/dashboard/events/_id/task/index.vue' /* webpackChunkName: "pages/dashboard/events/_id/task/index" */))
const _2977c034 = () => interopDefault(import('../pages/tools/submit-wedding/_slug/preview/index.vue' /* webpackChunkName: "pages/tools/submit-wedding/_slug/preview/index" */))
const _b95504e6 = () => interopDefault(import('../pages/tools/vendor-submit-wedding/_slug/preview/index.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/_slug/preview/index" */))
const _3265d454 = () => interopDefault(import('../pages/tools/submit-wedding/_slug/form/done.vue' /* webpackChunkName: "pages/tools/submit-wedding/_slug/form/done" */))
const _7d4967af = () => interopDefault(import('../pages/tools/vendor-submit-wedding/_slug/form/done.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/_slug/form/done" */))
const _2cfbd81a = () => interopDefault(import('../pages/dashboard/posse/_user/events/_id/index.vue' /* webpackChunkName: "pages/dashboard/posse/_user/events/_id/index" */))
const _242a28f2 = () => interopDefault(import('../pages/tools/submit-wedding/_slug/form/_step.vue' /* webpackChunkName: "pages/tools/submit-wedding/_slug/form/_step" */))
const _26f8d4ce = () => interopDefault(import('../pages/tools/vendor-submit-wedding/_slug/form/_step.vue' /* webpackChunkName: "pages/tools/vendor-submit-wedding/_slug/form/_step" */))
const _6a3ddaa0 = () => interopDefault(import('../pages/dashboard/posse/_user/events/_id/task/index.vue' /* webpackChunkName: "pages/dashboard/posse/_user/events/_id/task/index" */))
const _2d947d9c = () => interopDefault(import('../pages/real-weddings/taxonomy/_taxonomyid/_taxonomy/index.vue' /* webpackChunkName: "pages/real-weddings/taxonomy/_taxonomyid/_taxonomy/index" */))
const _0ccf92b0 = () => interopDefault(import('../pages/shop/taxonomy/_taxonomyid/_taxonomy/index.vue' /* webpackChunkName: "pages/shop/taxonomy/_taxonomyid/_taxonomy/index" */))
const _4f95bfb4 = () => interopDefault(import('../pages/authors/_username.vue' /* webpackChunkName: "pages/authors/_username" */))
const _301bf836 = () => interopDefault(import('../pages/complete-profile-idc/_step.vue' /* webpackChunkName: "pages/complete-profile-idc/_step" */))
const _269973a1 = () => interopDefault(import('../pages/complete/_slug/index.vue' /* webpackChunkName: "pages/complete/_slug/index" */))
const _f4a6a19a = () => interopDefault(import('../pages/forms/_slug/index.vue' /* webpackChunkName: "pages/forms/_slug/index" */))
const _5fb5ec1b = () => interopDefault(import('../pages/guides/_guide.vue' /* webpackChunkName: "pages/guides/_guide" */))
const _3a7daafa = () => interopDefault(import('../pages/payment/_id/index.vue' /* webpackChunkName: "pages/payment/_id/index" */))
const _455462e8 = () => interopDefault(import('../pages/planning/_taxonomy.vue' /* webpackChunkName: "pages/planning/_taxonomy" */))
const _942dbe14 = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _8be2af12 = () => interopDefault(import('../pages/style-quiz/_step.vue' /* webpackChunkName: "pages/style-quiz/_step" */))
const _1db61077 = () => interopDefault(import('../pages/users/_username/index.vue' /* webpackChunkName: "pages/users/_username/index" */))
const _560f2aec = () => interopDefault(import('../pages/payment/_id/done.vue' /* webpackChunkName: "pages/payment/_id/done" */))
const _0404b27b = () => interopDefault(import('../pages/users/_username/boards/index.vue' /* webpackChunkName: "pages/users/_username/boards/index" */))
const _6c8755e0 = () => interopDefault(import('../pages/users/_username/courses/index.vue' /* webpackChunkName: "pages/users/_username/courses/index" */))
const _78170849 = () => interopDefault(import('../pages/users/_username/guests/index.vue' /* webpackChunkName: "pages/users/_username/guests/index" */))
const _6d83dc29 = () => interopDefault(import('../pages/users/_username/rsvp/index.vue' /* webpackChunkName: "pages/users/_username/rsvp/index" */))
const _58d2a411 = () => interopDefault(import('../pages/users/_username/settings/index.vue' /* webpackChunkName: "pages/users/_username/settings/index" */))
const _f06fd30e = () => interopDefault(import('../pages/users/_username/courses/downloads.vue' /* webpackChunkName: "pages/users/_username/courses/downloads" */))
const _0122814e = () => interopDefault(import('../pages/users/_username/courses/guides.vue' /* webpackChunkName: "pages/users/_username/courses/guides" */))
const _7becb25a = () => interopDefault(import('../pages/users/_username/courses/perks.vue' /* webpackChunkName: "pages/users/_username/courses/perks" */))
const _74447a74 = () => interopDefault(import('../pages/users/_username/courses/products.vue' /* webpackChunkName: "pages/users/_username/courses/products" */))
const _6bacc15d = () => interopDefault(import('../pages/users/_username/rsvp/iframe.vue' /* webpackChunkName: "pages/users/_username/rsvp/iframe" */))
const _2ef9d0ba = () => interopDefault(import('../pages/users/_username/boards/_id.vue' /* webpackChunkName: "pages/users/_username/boards/_id" */))
const _6ad0b498 = () => interopDefault(import('../pages/users/_username/courses/_slug.vue' /* webpackChunkName: "pages/users/_username/courses/_slug" */))
const _6cf9691b = () => interopDefault(import('../pages/users/_username/courses/_slug/index.vue' /* webpackChunkName: "pages/users/_username/courses/_slug/index" */))
const _0c0cd663 = () => interopDefault(import('../pages/users/_username/courses/_slug/_lesson/index.vue' /* webpackChunkName: "pages/users/_username/courses/_slug/_lesson/index" */))
const _ff9e5298 = () => interopDefault(import('../pages/users/_username/guests/_id/index.vue' /* webpackChunkName: "pages/users/_username/guests/_id/index" */))
const _6609c7d8 = () => interopDefault(import('../pages/users/_username/settings/_type.vue' /* webpackChunkName: "pages/users/_username/settings/_type" */))
const _56df0849 = () => interopDefault(import('../pages/users/_username/guests/_id/rsvp.vue' /* webpackChunkName: "pages/users/_username/guests/_id/rsvp" */))
const _9d74140c = () => interopDefault(import('../pages/complete/_slug/_step.vue' /* webpackChunkName: "pages/complete/_slug/_step" */))
const _7dc3cf1e = () => interopDefault(import('../pages/questionnaires/_slug/_step.vue' /* webpackChunkName: "pages/questionnaires/_slug/_step" */))
const _1bff6f2f = () => interopDefault(import('../pages/users/_username/_slug.vue' /* webpackChunkName: "pages/users/_username/_slug" */))
const _ddeb4f36 = () => interopDefault(import('../pages/complete/_slug/_id/_step.vue' /* webpackChunkName: "pages/complete/_slug/_id/_step" */))
const _3d6c1559 = () => interopDefault(import('../pages/_removed_/wedding-invitations/index.vue' /* webpackChunkName: "pages/_removed_/wedding-invitations/index" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/image-viewer/:username/:parent/:id",
    component: _6d1eccb7,
    name: "image-viewer-username-parent-id"
  }, {
    path: "/image-viewer/:parent/:id",
    component: _6d1eccb7,
    name: "image-viewer-parent-id"
  }, {
    path: "/image-viewer/:id",
    component: _6d1eccb7,
    name: "image-viewer-id"
  }, {
    path: "/authors",
    component: _33a86351,
    name: "authors"
  }, {
    path: "/best-wedding-vendors-2024",
    component: _3dbcb3e6,
    name: "best-wedding-vendors-2024"
  }, {
    path: "/change-password",
    component: _0012cdc4,
    name: "change-password"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/forms",
    component: _7361e410,
    name: "forms"
  }, {
    path: "/guides",
    component: _4d5be7a4,
    name: "guides"
  }, {
    path: "/i-do-crew",
    component: _a4497ff6,
    name: "i-do-crew"
  }, {
    path: "/planning",
    component: _185fd3ec,
    name: "planning"
  }, {
    path: "/podcast",
    component: _2e919b0d,
    name: "podcast"
  }, {
    path: "/questionnaires",
    component: _ea1f76c6,
    name: "questionnaires"
  }, {
    path: "/real-weddings",
    component: _4b9519f7,
    name: "real-weddings"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search"
  }, {
    path: "/shop",
    component: _34ed5763,
    name: "shop"
  }, {
    path: "/sign-in",
    component: _211f388a,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _0406ba31,
    name: "sign-up"
  }, {
    path: "/social-callback",
    component: _3a585daa,
    name: "social-callback"
  }, {
    path: "/style-quiz",
    component: _2f62261e,
    name: "style-quiz"
  }, {
    path: "/tools",
    component: _45d7b6b8,
    name: "tools"
  }, {
    path: "/vendors",
    component: _15de1774,
    name: "vendors"
  }, {
    path: "/dashboard/events",
    component: _75a474e1,
    name: "dashboard-events"
  }, {
    path: "/event-invitation/n______deactivated_loverly-list-event-2024",
    component: _c6bf27e4,
    name: "event-invitation-n______deactivated_loverly-list-event-2024"
  }, {
    path: "/style-quiz/result",
    component: _70e49701,
    name: "style-quiz-result"
  }, {
    path: "/tools/cash-registry",
    component: _63fbccac,
    name: "tools-cash-registry"
  }, {
    path: "/tools/guest-list",
    component: _fd279758,
    name: "tools-guest-list"
  }, {
    path: "/tools/offers",
    component: _b392f496,
    name: "tools-offers"
  }, {
    path: "/tools/submit-wedding",
    component: _59c5b780,
    name: "tools-submit-wedding"
  }, {
    path: "/tools/vendor-manager",
    component: _64aeb06e,
    name: "tools-vendor-manager"
  }, {
    path: "/tools/vendor-submit-wedding",
    component: _3b2656ce,
    name: "tools-vendor-submit-wedding"
  }, {
    path: "/tools/videos",
    component: _1ad1fcf9,
    name: "tools-videos"
  }, {
    path: "/tools/wedding-checklist",
    component: _02e70302,
    name: "tools-wedding-checklist"
  }, {
    path: "/vendor/checklist",
    component: _69c6d868,
    name: "vendor-checklist"
  }, {
    path: "/vendor/dashboard",
    component: _868a498c,
    name: "vendor-dashboard"
  }, {
    path: "/tools/cash-registry/setup",
    component: _c14b4bcc,
    name: "tools-cash-registry-setup"
  }, {
    path: "/tools/create-or-claim-vendor-profile/setup",
    component: _61acd2fd,
    name: "tools-create-or-claim-vendor-profile-setup"
  }, {
    path: "/tools/guest-list/setup",
    component: _0e6e787f,
    name: "tools-guest-list-setup"
  }, {
    path: "/tools/submit-wedding/create",
    component: _06a5833e,
    name: "tools-submit-wedding-create"
  }, {
    path: "/tools/submit-wedding/email",
    component: _084a654a,
    name: "tools-submit-wedding-email"
  }, {
    path: "/tools/submit-wedding/my-weddings",
    component: _6337d3ce,
    name: "tools-submit-wedding-my-weddings"
  }, {
    path: "/tools/vendor-manager/hired",
    component: _157bbfe7,
    name: "tools-vendor-manager-hired"
  }, {
    path: "/tools/vendor-manager/setup",
    component: _5aaaebf4,
    name: "tools-vendor-manager-setup"
  }, {
    path: "/tools/vendor-submit-wedding/create",
    component: _da3bed76,
    name: "tools-vendor-submit-wedding-create"
  }, {
    path: "/tools/vendor-submit-wedding/email",
    component: _de1cfb3a,
    name: "tools-vendor-submit-wedding-email"
  }, {
    path: "/tools/vendor-submit-wedding/my-weddings",
    component: _5c5e0bb2,
    name: "tools-vendor-submit-wedding-my-weddings"
  }, {
    path: "/tools/vision-boards/settings",
    component: _d6d86544,
    name: "tools-vision-boards-settings"
  }, {
    path: "/tools/vision-boards/setup",
    component: _448baa55,
    name: "tools-vision-boards-setup"
  }, {
    path: "/tools/wedding-checklist/settings",
    component: _5fb044ba,
    name: "tools-wedding-checklist-settings"
  }, {
    path: "/tools/wedding-checklist/setup",
    component: _e02d71a6,
    name: "tools-wedding-checklist-setup"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/tools/create-or-claim-vendor-profile/setup/:step?",
    component: _274d5554,
    name: "tools-create-or-claim-vendor-profile-setup-step"
  }, {
    path: "/tools/videos/categories/:taxonomy",
    component: _da6b5fae,
    name: "tools-videos-categories-taxonomy"
  }, {
    path: "/tools/vision-boards/setup/:step?",
    component: _618fa6a4,
    name: "tools-vision-boards-setup-step"
  }, {
    path: "/vendor/dashboard/posse/:user?/events/:id?",
    component: _5722dc5a,
    name: "vendor-dashboard-posse-user-events-id"
  }, {
    path: "/dashboard/events/:id",
    component: _2cf39168,
    name: "dashboard-events-id"
  }, {
    path: "/internal/paid-documents/:id?",
    component: _3112498c,
    name: "internal-paid-documents-id"
  }, {
    path: "/real-weddings/styles/:taxonomy",
    component: _f35f599e,
    name: "real-weddings-styles-taxonomy"
  }, {
    path: "/tools/courses/:slug?",
    component: _54e24bc5,
    name: "tools-courses-slug"
  }, {
    path: "/tools/guest-list/:id?",
    component: _1d633bbc,
    name: "tools-guest-list-id"
  }, {
    path: "/vendor/management/:step?",
    component: _68ea6e68,
    name: "vendor-management-step"
  }, {
    path: "/vendors/services/:taxonomy",
    component: _cca1e960,
    name: "vendors-services-taxonomy"
  }, {
    path: "/dashboard/events/:id/task",
    component: _73673a84,
    name: "dashboard-events-id-task"
  }, {
    path: "/tools/submit-wedding/:slug?/preview",
    component: _2977c034,
    name: "tools-submit-wedding-slug-preview"
  }, {
    path: "/tools/vendor-submit-wedding/:slug?/preview",
    component: _b95504e6,
    name: "tools-vendor-submit-wedding-slug-preview"
  }, {
    path: "/tools/submit-wedding/:slug?/form/done",
    component: _3265d454,
    name: "tools-submit-wedding-slug-form-done"
  }, {
    path: "/tools/vendor-submit-wedding/:slug?/form/done",
    component: _7d4967af,
    name: "tools-vendor-submit-wedding-slug-form-done"
  }, {
    path: "/dashboard/posse/:user?/events/:id",
    component: _2cfbd81a,
    name: "dashboard-posse-user-events-id"
  }, {
    path: "/tools/submit-wedding/:slug?/form/:step?",
    component: _242a28f2,
    name: "tools-submit-wedding-slug-form-step"
  }, {
    path: "/tools/vendor-submit-wedding/:slug?/form/:step",
    component: _26f8d4ce,
    name: "tools-vendor-submit-wedding-slug-form-step"
  }, {
    path: "/dashboard/posse/:user?/events/:id?/task",
    component: _6a3ddaa0,
    name: "dashboard-posse-user-events-id-task"
  }, {
    path: "/real-weddings/taxonomy/:taxonomyid/:taxonomy",
    component: _2d947d9c,
    name: "real-weddings-taxonomy-taxonomyid-taxonomy"
  }, {
    path: "/shop/taxonomy/:taxonomyid?/:taxonomy",
    component: _0ccf92b0,
    name: "shop-taxonomy-taxonomyid-taxonomy"
  }, {
    path: "/authors/:username",
    component: _4f95bfb4,
    name: "authors-username"
  }, {
    path: "/complete-profile-idc/:step?",
    component: _301bf836,
    name: "complete-profile-idc-step"
  }, {
    path: "/complete/:slug",
    component: _269973a1,
    name: "complete-slug"
  }, {
    path: "/forms/:slug",
    component: _f4a6a19a,
    name: "forms-slug"
  }, {
    path: "/guides/:guide",
    component: _5fb5ec1b,
    name: "guides-guide"
  }, {
    path: "/payment/:id",
    component: _3a7daafa,
    name: "payment-id"
  }, {
    path: "/planning/:taxonomy",
    component: _455462e8,
    name: "planning-taxonomy"
  }, {
    path: "/reset-password/:token?",
    component: _942dbe14,
    name: "reset-password-token"
  }, {
    path: "/style-quiz/:step?",
    component: _8be2af12,
    name: "style-quiz-step"
  }, {
    path: "/users/:username",
    component: _1db61077,
    name: "users-username"
  }, {
    path: "/payment/:id?/done",
    component: _560f2aec,
    name: "payment-id-done"
  }, {
    path: "/users/:username?/boards",
    component: _0404b27b,
    name: "users-username-boards"
  }, {
    path: "/users/:username?/courses",
    component: _6c8755e0,
    name: "users-username-courses"
  }, {
    path: "/users/:username?/guests",
    component: _78170849,
    name: "users-username-guests"
  }, {
    path: "/users/:username?/rsvp",
    component: _6d83dc29,
    name: "users-username-rsvp"
  }, {
    path: "/users/:username?/settings",
    component: _58d2a411,
    name: "users-username-settings"
  }, {
    path: "/users/:username?/courses/downloads",
    component: _f06fd30e,
    name: "users-username-courses-downloads"
  }, {
    path: "/users/:username?/courses/guides",
    component: _0122814e,
    name: "users-username-courses-guides"
  }, {
    path: "/users/:username?/courses/perks",
    component: _7becb25a,
    name: "users-username-courses-perks"
  }, {
    path: "/users/:username?/courses/products",
    component: _74447a74,
    name: "users-username-courses-products"
  }, {
    path: "/users/:username?/rsvp/iframe",
    component: _6bacc15d,
    name: "users-username-rsvp-iframe"
  }, {
    path: "/users/:username?/boards/:id",
    component: _2ef9d0ba,
    name: "users-username-boards-id"
  }, {
    path: "/users/:username?/courses/:slug",
    component: _6ad0b498,
    children: [{
      path: "",
      component: _6cf9691b,
      name: "users-username-courses-slug"
    }, {
      path: ":lesson",
      component: _0c0cd663,
      name: "users-username-courses-slug-lesson"
    }]
  }, {
    path: "/users/:username?/guests/:id",
    component: _ff9e5298,
    name: "users-username-guests-id"
  }, {
    path: "/users/:username?/settings/:type",
    component: _6609c7d8,
    name: "users-username-settings-type"
  }, {
    path: "/users/:username?/guests/:id/rsvp",
    component: _56df0849,
    name: "users-username-guests-id-rsvp"
  }, {
    path: "/complete/:slug?/:step",
    component: _9d74140c,
    name: "complete-slug-step"
  }, {
    path: "/questionnaires/:slug/:step?",
    component: _7dc3cf1e,
    name: "questionnaires-slug-step"
  }, {
    path: "/users/:username?/:slug",
    component: _1bff6f2f,
    name: "users-username-slug"
  }, {
    path: "/complete/:slug?/:id/:step?",
    component: _ddeb4f36,
    name: "complete-slug-id-step"
  }, {
    path: "/:removed_/wedding-invitations",
    component: _3d6c1559,
    name: "removed_-wedding-invitations"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
